import type { BaseRouteRpc } from '@repo/contract';
import { FetchError } from 'ofetch';
import { type ComputedRef, isRef } from 'vue';
import { sendShowNotification } from '../composables/notification/notification-emitter';

const INTERNAL_SERVER_ERROR = 500;

export interface FetchRpcOptions<T extends typeof BaseRouteRpc> {
  successNotificationMessage?: string | ((response: T['response']) => string)
  errorNotificationDuration?: number
};

function getSuccessNotificationMessage<T extends typeof BaseRouteRpc>(response: T['response'], successNotificationMessage: FetchRpcOptions<T>['successNotificationMessage']) {
  if (typeof successNotificationMessage === 'function') {
    return successNotificationMessage(response);
  }

  return successNotificationMessage;
}

export async function fetchRpc<T extends typeof BaseRouteRpc>(
  req: Parameters<typeof $fetch>[0],
  body: T['request'] | ComputedRef<T['request']>,
  {
    successNotificationMessage,
    errorNotificationDuration,
  }: FetchRpcOptions<T> = {},
) {
  try {
    const response = await $fetch<T['response']>(req, {
      method: 'POST',
      body: isRef(body) ? body.value : body,
    });

    if (successNotificationMessage) {
      sendShowNotification({
        type: 'success',
        message: getSuccessNotificationMessage<T>(response, successNotificationMessage),
      });
    }

    return response;
  }
  catch (e) {
    let notificationMessage = 'Client error occurred.';

    if (e instanceof FetchError) {
      notificationMessage = 'Server error. Please try again.';

      if (e.response?.status !== INTERNAL_SERVER_ERROR) {
        const responseMessage = e.data as string ?? e.data?.message;

        notificationMessage = responseMessage ?? notificationMessage;
      }

      console.log('FetchError: ', e.data, e.message, e.response);
    }
    else {
      console.error(e);
    }

    sendShowNotification(
      { type: 'error', message: notificationMessage, duration: errorNotificationDuration },
    );

    throw e;
  }
}
